import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable } from '@angular/core';

import { EventSeverity } from '../model/error.event';
import { ErrorTrackingService } from '../services/error-tracking.service';

@Injectable()
export class ErrorHandlerService extends ErrorHandler {
  constructor(private tracking: ErrorTrackingService) {
    super();
  }

  override handleError(error: Error) {
    console.error(error);

    if (error instanceof HttpErrorResponse) {
      return;
    }

    this.tracking.trackRuntimeError(error, EventSeverity.error);
  }
}
