import { CrmAppConfiguration } from 'common-module/core/types';
import {
  CrmLayoutCollapseComponent,
  CrmLayoutLocaleComponent,
  CrmLayoutLogoComponent,
  CrmLayoutMenuComponent,
  CrmLayoutProfileComponent,
  CrmLayoutProfileItemKey,
} from 'common-module/layout-v2';
import { CrmTableConfig } from 'common-module/table';

import { RoutesConfig } from '~/config/routes.config';
import { LayoutChatComponent } from '~/crm/layout/layout-chat.component';
import { LayoutCollapseProvider } from '~/crm/layout/layout-collapse.provider';
import { LayoutLogoProvider } from '~/crm/layout/layout-logo.provider';
import { LayoutMenuProvider } from '~/crm/layout/layout-menu.provider';
import { LayoutSearchComponent } from '~/crm/layout/search/layout-search.component';
import { LayoutVersionComponent } from '~/crm/layout/version/layout-version.component';
import { environment } from '~/environments/environment';

export const appConfig: CrmAppConfiguration = {
  environment,
  modules: {
    layoutV2: {
      header: {
        fragments: [
          {
            key: 'collapse',
            content: CrmLayoutCollapseComponent,
            provider: LayoutCollapseProvider,
          },
          {
            key: 'logo',
            content: CrmLayoutLogoComponent,
            provider: LayoutLogoProvider,
          },
          {
            key: 'chat',
            content: LayoutChatComponent,
          },
          {
            key: 'search',
            content: LayoutSearchComponent,
          },
          {
            key: 'locale',
            content: CrmLayoutLocaleComponent,
          },
          {
            key: 'profile',
            content: CrmLayoutProfileComponent,
          },
        ],
      },
      siderLeft: {
        collapsedWidth: 80,
        fragments: [
          {
            key: 'menu',
            content: CrmLayoutMenuComponent,
            provider: LayoutMenuProvider,
          },
          {
            key: 'version',
            content: LayoutVersionComponent,
          },
        ],
      },
      profile: {
        roleMap: {
          admin: 'Admin',
          management: 'Management',
          receptionist: 'Receptionist',
          doctor: 'Doctor',
          nurse: 'Nurse',
        },
        items: [
          {
            key: 'changelog',
            title: 'crm.changelog.header.title',
            route: RoutesConfig.CHANGELOG,
          },
          {
            key: CrmLayoutProfileItemKey.LOGOUT,
            title: 'generic.logout',
          },
        ],
        dropdownTrigger: 'click',
        dropdownIcon: 'down',
        dropdownIconVisible: 'up',
        dropdownPlacement: 'bottom',
      },
      collapse: {
        icon: 'menu-fold',
        iconCollapsed: 'menu-unfold',
      },
      content: undefined,
    },
    table: {
      component: {
        emptyState: { title: 'generic.noData' },
      },
    } as CrmTableConfig,
  },
} as const;
