import { PlatformModule } from '@angular/cdk/platform';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import {
  APP_INITIALIZER,
  ErrorHandler,
  ModuleWithProviders,
  NgModule,
} from '@angular/core';
import { RouterModule } from '@angular/router';

import { ErrorHandlerService } from './handler/error-handler.service';
import { TrackingInterceptor } from './interceptors/tracking.interceptor';
import { ErrorTrackingBuilderService } from './services/error-tracking-builder.service';
import { ErrorTrackingService } from './services/error-tracking.service';

@NgModule({
  imports: [PlatformModule, RouterModule],
})
export class TrackingModule {
  static forRoot(): ModuleWithProviders<TrackingModule> {
    return {
      ngModule: TrackingModule,
      providers: [
        ErrorTrackingService,
        ErrorTrackingBuilderService,
        {
          provide: ErrorHandler,
          useClass: ErrorHandlerService,
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: TrackingInterceptor,
          multi: true,
        },
        {
          provide: APP_INITIALIZER,
          multi: true,
          deps: [ErrorTrackingService],
          useFactory: (service: ErrorTrackingService) => () => service.init(),
        },
      ],
    };
  }
}
