import { UpperCasePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  signal,
} from '@angular/core';
import { CrmBaseLoginComponent } from 'common-module/auth';
import { CrmFormComponent } from 'common-module/form';
import { CrmTranslatePipe } from 'common-module/translate';
import { NzAlertModule } from 'ng-zorro-antd/alert';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { take } from 'rxjs';

type LoginMessage = {
  message: string;
  type: 'error' | 'success';
};

const LoginMessages = {
  badCredentialsTwoFactor: {
    message: 'twoFactor2Message',
    type: 'error',
  },
  badCredentialsNoTwoFactor: {
    message: 'badCredentialsMessage',
    type: 'error',
  },
  twoFactor: {
    message: 'twoFactorMessage',
    type: 'error',
  },
  externalError: (message: string): LoginMessage => ({
    message,
    type: 'error',
  }),
  passwordChanged: {
    message: 'passwordChanged',
    type: 'success',
  },
} as const;

@Component({
  standalone: true,
  selector: 'app-login',
  templateUrl: 'login.component.html',
  imports: [
    CrmTranslatePipe,
    CrmFormComponent,
    NzIconModule,
    NzButtonModule,
    UpperCasePipe,
    NzAlertModule,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginComponent extends CrmBaseLoginComponent implements OnInit {
  message = signal<LoginMessage | undefined>(undefined);

  ngOnInit() {
    // TODO: token login redirect

    this.route.queryParams.pipe(take(1)).subscribe((qp) => {
      if (qp['passwordChanged']) {
        this.message.set(LoginMessages.passwordChanged);
      }
      if (qp['externalError']) {
        this.message.set(LoginMessages.externalError(qp['externalError']));
      }
    });
  }

  override handleForgottenPassword() {
    if (this.isLoading()) {
      return;
    }

    super.handleForgottenPassword();
  }

  protected override handleError(err: HttpErrorResponse) {
    switch (err.status) {
      case 401:
        // TODO: two factor redirect
        return;
      case 403:
        this.message.set(LoginMessages.badCredentialsNoTwoFactor);
        break;
    }
  }
}
