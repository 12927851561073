import { CrmAuthConfig } from 'common-module/auth';

import { AuthImageComponent } from '~/crm/auth/image/auth-image.component';
import { LoginComponent } from '~/crm/auth/login/login.component';
import { LoginProvider } from '~/crm/auth/login/login.provider';

export const authConfig: CrmAuthConfig = {
  interceptor: {
    interceptHttpCode: [401, 403],
    ignoredInterceptURLs: ['/logout'],
  },
  components: {
    login: {
      content: {
        content: LoginComponent,
      },
      providers: {
        login: LoginProvider,
      },
    },
    wrapper: {
      imagePosition: 'right',
      layout: 'image',
      imageContent: {
        content: AuthImageComponent,
      },
      logoSrc: '/assets/images/logo-docut.svg',
    },
  },
};
