import { NgModule } from '@angular/core';
import ss from 'streamsaver';
import { WritableStream } from 'web-streams-polyfill/ponyfill';

import { ExportStreamService } from './services/export-stream.service';
import { StringStreamService } from './services/string-stream.service';

@NgModule({
  providers: [StringStreamService, ExportStreamService],
})
export class StreamModule {
  constructor() {
    if (!ss.WritableStream) {
      // required for browsers without WritableStream
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      ss.WritableStream = WritableStream;
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    ss.mitm = `/assets/static/streamsaver/mitm.html?v=0.2.0`;
  }
}
