import { NgIf } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { CrmTranslatePipe } from 'common-module/translate';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzInputModule } from 'ng-zorro-antd/input';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';

@Component({
  standalone: true,
  selector: 'app-search-input',
  templateUrl: './search-input.component.html',
  styleUrls: ['./search-input.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    NzInputModule,
    ReactiveFormsModule,
    CrmTranslatePipe,
    NzIconModule,
    NgIf,
  ],
})
export class SearchInputComponent implements OnInit, AfterViewInit, OnChanges {
  @ViewChild('inputSearch') public input!: ElementRef<HTMLInputElement>;

  @Input() public id?: string;
  @Input() public value?: string;
  @Input() public autofocus = false;
  @Input() public placeholder = 'generic.search';

  @Output() public valueChanged: EventEmitter<string> =
    new EventEmitter<string>();

  public control!: FormControl;

  public ngOnInit() {
    this.control = new FormControl();
    this.control.valueChanges
      .pipe(
        tap((value) => (this.value = value)),
        debounceTime(300),
        distinctUntilChanged()
      )
      .subscribe((value) => {
        this.valueChanged.emit(value);
      });

    if (this.value) {
      this.control.setValue(this.value);
    }
  }

  public ngAfterViewInit() {
    if (this.autofocus && this.input.nativeElement) {
      setTimeout(() => {
        this.input.nativeElement.focus();
      });
    }
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['value'] && !changes['value'].firstChange) {
      this.control.setValue(changes['value'].currentValue);
    }
  }

  public handleClear() {
    this.control.setValue('');
  }
}
