import { inject, Injectable } from '@angular/core';
import { CrmDictionary } from 'common-module/core/types';
import { compact, flatten, map as lMap } from 'lodash-es';
import { BehaviorSubject, forkJoin, map, of, switchMap } from 'rxjs';

import { PatientsApiService } from '~/api/patient/patients-api.service';
import { RecordMedicalApiService } from '~/api/record/record-medical-api.service';
import { UserApiService } from '~/api/user/user-api.service';
import { UserPermissionsService } from '~/api/user/user-permissions.service';
import { getFullName, UserModel } from '~/api/user/user.model';
import { UserPermissionKey } from '~/api/user/user.permissions';
import { getDate } from '~/shared/utils/date/date-format';
import { SEARCH_KEY } from '~/shared/utils/filter/search';
import { safeListUsersByIDs } from '~/shared/utils/list/safe-list';

interface SearchData<Data> {
  data: Data[];
  hasMore: boolean;
}

export type LayoutSearchData = {
  empty: boolean;
  customers: SearchData<{
    id: string;
    name: string;
    email?: string;
    phone?: string;
  }>;
  patients: SearchData<{
    id: string;
    name?: string;
    species?: string;
    breed?: string;
    patrons: { id: string; name: string }[];
  }>;
  records: SearchData<{
    id: string;
    title?: string;
    author?: { id: string; name: string };
    created: Date;
  }>;
};

@Injectable()
export class LayoutSearchProvider {
  readonly loading$ = new BehaviorSubject(false);
  readonly data$ = new BehaviorSubject<LayoutSearchData>(this.getEmptyData());

  protected moreCustomersAvailable = false;
  protected morePatientsAvailable = false;
  protected moreRecordsAvailable = false;

  protected limit = 3;

  private readonly userApiService = inject(UserApiService);
  private readonly patientsApiService = inject(PatientsApiService);
  private readonly medicalApiService = inject(RecordMedicalApiService);
  private readonly permissionsService = inject(UserPermissionsService);

  search(value?: string) {
    if (!value?.length) {
      this.data$.next(this.getEmptyData());
      return;
    }

    const params = {
      [SEARCH_KEY]: value,
      $limit: this.limit,
    };

    this.loading$.next(true);

    forkJoin({
      customers: this.getCustomers(params),
      patients: this.getPatients(params),
      records: this.getRecords(params),
    })
      .pipe(
        switchMap(({ customers, patients, records }) => {
          const patrons = flatten(lMap(patients, 'patrons'));
          const authors = lMap(records, 'author');
          const ids = [...patrons, ...authors];
          return safeListUsersByIDs(this.userApiService, compact(ids)).pipe(
            map((users) => {
              return {
                customers,
                patients: patients.map((p) => {
                  p.patronsData = compact(
                    (p.patrons ?? []).map((patron) =>
                      users.find((u) => u._id === patron)
                    )
                  );
                  return p;
                }),
                records: records.map((r) => {
                  r.authorData = users.find((u) => u._id === r.author)!;
                  return r;
                }),
              };
            })
          );
        })
      )
      .subscribe(({ customers, patients, records }) => {
        this.loading$.next(false);
        this.data$.next({
          empty:
            customers.length === 0 &&
            patients.length === 0 &&
            records.length === 0,
          customers: {
            data: customers.map((customer) => ({
              id: customer._id,
              name: getFullName(customer),
              phone: customer.phone,
              email: customer.email,
            })),
            hasMore: this.moreCustomersAvailable,
          },
          patients: {
            data: patients.map((patient) => ({
              id: patient.id,
              name: patient.name,
              species: patient.species,
              breed: patient.breed,
              patrons: patient.patronsData.map((patron) => ({
                id: patron._id,
                name: getFullName(patron),
              })),
            })),
            hasMore: this.morePatientsAvailable,
          },
          records: {
            data: records.map((record) => ({
              id: record.id,
              title: record.title,
              author: record.authorData
                ? {
                    id: record.authorData._id,
                    name: getFullName(record.authorData),
                  }
                : undefined,
              created: getDate(record.created)!,
            })),
            hasMore: this.moreRecordsAvailable,
          },
        });
      });
  }

  private getCustomers(params: CrmDictionary) {
    if (this.permissionsService.canDisplay(UserPermissionKey.CUSTOMERS)) {
      return this.userApiService.listCustomers(params).pipe(
        map((resp) => {
          this.moreCustomersAvailable = resp.total > this.limit;
          return resp.data;
        })
      );
    }

    return of([]);
  }

  private getPatients(params: CrmDictionary) {
    if (this.permissionsService.canDisplay(UserPermissionKey.PATIENTS)) {
      return this.patientsApiService.list(params).pipe(
        map((resp) => {
          this.morePatientsAvailable = resp.total > this.limit;
          return resp.data.map((data) => ({
            id: data._id,
            name: data.name,
            species: data.species,
            breed: data.breed,
            patrons: data.patrons,
            patronsData: [] as UserModel[],
          }));
        })
      );
    }

    return of([]);
  }

  private getRecords(params: CrmDictionary) {
    if (this.permissionsService.canDisplay(UserPermissionKey.RECORDS)) {
      return this.medicalApiService.list(params).pipe(
        map((resp) => {
          this.moreRecordsAvailable = resp.total > this.limit;
          return resp.data.map((data) => ({
            id: data._id,
            title: data.title,
            author: data.author,
            authorData: {} as UserModel,
            created: data.createdAt,
          }));
        })
      );
    }

    return of([]);
  }

  private getEmptyData(): LayoutSearchData {
    return {
      empty: true,
      customers: {
        data: [],
        hasMore: false,
      },
      patients: {
        data: [],
        hasMore: false,
      },
      records: {
        data: [],
        hasMore: false,
      },
    };
  }
}
