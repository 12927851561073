import { Injectable } from '@angular/core';
import { CrmLoginProviderImpl } from 'common-module/auth';

import { UserModel } from '~/api/user/user.model';
import { RoutesConfig } from '~/config/routes.config';

@Injectable()
export class LoginProvider extends CrmLoginProviderImpl {
  protected override navigateTo(user: UserModel) {
    if (user.roles.signature) {
      this.router.navigate([RoutesConfig.SIGNATURE]);
      return;
    }

    if (user.roles.selfservice) {
      this.router.navigate([RoutesConfig.SELF_SERVICE]);
      return;
    }

    if (user.roles.attendance) {
      this.router.navigate([RoutesConfig.ATTENDANCE]);
      return;
    }

    this.router.navigate(['']);
  }
}
