import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  inject,
  OnInit,
} from '@angular/core';
import { Router } from '@angular/router';
import { RxLet } from '@rx-angular/template/let';
import { RxPush } from '@rx-angular/template/push';
import { CrmLayoutV2FragmentDirective } from 'common-module/layout-v2';
import { CrmTranslatePipe } from 'common-module/translate';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzEmptyModule } from 'ng-zorro-antd/empty';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { Observable } from 'rxjs';

import { RoutesConfig } from '~/config/routes.config';
import { ContentLoadingComponent } from '~/shared/components/content-loading/content-loading.component';
import { DrawerComponent } from '~/shared/components/drawer/drawer.component';
import { SearchInputComponent } from '~/shared/components/inputs/search/search-input.component';
import { FormatDateTimePipe } from '~/shared/pipes/format.pipe';
import { HighlightValuePipe } from '~/shared/pipes/string.pipe';
import { SEARCH_KEY } from '~/shared/utils/filter/search';

import {
  LayoutSearchData,
  LayoutSearchProvider,
} from './layout-search.provider';

@Component({
  standalone: true,
  selector: 'app-layout-search',
  template: `
    <i
      nz-icon
      nzType="search"
      nzTheme="outline"
      class="search-icon"
      (click)="handleOpen()"
      id="supersearch-btn"
    ></i>
    <app-drawer
      [visible]="visible"
      [body]="body"
      (close$)="handleClose()"
    ></app-drawer>

    <ng-template #body>
      <app-search-input
        id="supersearch"
        [value]="value"
        [autofocus]="true"
        (valueChanged)="searchChange($event)"
      ></app-search-input>
      <div class="mt-2 mb-2"><nz-divider></nz-divider></div>
      <app-content-loading [loading]="loading$ | push" parentClass="mt-6">
        <ng-container *rxLet="data$; let data">
          <ng-container *ngIf="data.empty; else dataTmpl">
            <nz-empty></nz-empty>
          </ng-container>
          <ng-template #dataTmpl>
            <div class="ss-customers" *ngIf="data.customers.data.length">
              <div class="font-bold fs-18 mb-1">
                {{ 'customers.title' | crmTranslate }}
              </div>
              <div
                *ngFor="let c of data.customers.data"
                class="bordered-label mb-1 clickable"
                (click)="navigateToCustomer(c)"
              >
                <div [innerHTML]="c.name | highlightValue: value"></div>
                <div
                  class="fs-12"
                  [innerHTML]="c.email | highlightValue: value"
                ></div>
                <div
                  class="fs-12"
                  [innerHTML]="c.phone | highlightValue: value"
                ></div>
              </div>
              <div
                *ngIf="data.customers.hasMore"
                class="bordered-label clickable mb-1"
                (click)="navigateToCustomers()"
              >
                <div class="flex justify-content-between align-items-center">
                  <span>{{ 'generic.moreResults' | crmTranslate }}</span>
                  <i nz-icon nzType="double-right" nzTheme="outline"></i>
                </div>
              </div>
            </div>
            <div class="ss-patients" *ngIf="data.patients.data.length">
              <div class="font-bold fs-18 mb-1">
                {{ 'patients.title' | crmTranslate }}
              </div>
              <div
                *ngFor="let c of data.patients.data"
                class="bordered-label mb-1 clickable"
                (click)="navigateToPatient(c)"
              >
                <div [innerHTML]="c.name | highlightValue: value"></div>
                <div class="fs-12">
                  <span [innerHTML]="c.species | highlightValue: value"></span>,
                  <span [innerHTML]="c.breed | highlightValue: value"></span>
                </div>
                <div class="fs-12">
                  <ng-container *ngFor="let p of c.patrons; let isLast = last">
                    <span [innerHTML]="p.name | highlightValue: value"></span
                    ><ng-container *ngIf="!isLast">, </ng-container>
                  </ng-container>
                </div>
              </div>
              <div
                *ngIf="data.patients.hasMore"
                class="bordered-label clickable mb-1"
                (click)="navigateToPatients()"
              >
                <div class="flex justify-content-between align-items-center">
                  <span>{{ 'generic.moreResults' | crmTranslate }}</span>
                  <i nz-icon nzType="double-right" nzTheme="outline"></i>
                </div>
              </div>
            </div>
            <div class="ss-records" *ngIf="data.records.data.length">
              <div class="font-bold fs-18 mb-1">
                {{ 'generic.medicalRecords' | crmTranslate }}
              </div>
              <div
                *ngFor="let c of data.records.data"
                class="bordered-label mb-1 clickable"
                (click)="navigateToRecord(c)"
              >
                <div [innerHTML]="c.title | highlightValue: value"></div>
                <div
                  class="fs-12"
                  [innerHTML]="c.author?.name | highlightValue: value"
                ></div>
                <div class="fs-12">{{ c.created | formatDateTime }}</div>
              </div>
              <div
                *ngIf="data.records.hasMore"
                class="bordered-label clickable mb-1"
                (click)="navigateToRecords()"
              >
                <div class="flex justify-content-between align-items-center">
                  <span>{{ 'generic.moreResults' | crmTranslate }}</span>
                  <i nz-icon nzType="double-right" nzTheme="outline"></i>
                </div>
              </div>
            </div>
          </ng-template>
        </ng-container>
      </app-content-loading>
    </ng-template>
  `,
  styles: [
    `
      :host {
        display: flex;
        align-items: center;
        height: 100%;
        margin-right: 8px;
      }

      .search-icon {
        font-size: 24px;
        color: #7b7b7b;
        cursor: pointer;
      }

      .bordered-label {
        &:hover {
          background-color: #f6f6f6;
        }
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    NzIconModule,
    DrawerComponent,
    CommonModule,
    NzDividerModule,
    NzEmptyModule,
    NzIconModule,
    CrmTranslatePipe,
    SearchInputComponent,
    ContentLoadingComponent,
    RxPush,
    RxLet,
    HighlightValuePipe,
    FormatDateTimePipe,
  ],
  providers: [LayoutSearchProvider],
})
export class LayoutSearchComponent
  extends CrmLayoutV2FragmentDirective<LayoutSearchProvider>
  implements OnInit
{
  public value?: string;
  public visible = false;
  public loading$!: Observable<boolean>;
  public data$!: Observable<LayoutSearchData>;

  private readonly router = inject(Router);

  ngOnInit() {
    this.initProvider(LayoutSearchProvider);
    this.loading$ = this.provider.loading$;
    this.data$ = this.provider.data$;
  }

  public handleOpen(): void {
    this.visible = true;
  }

  public handleClose(): void {
    this.visible = false;
    this._clearSearch();
  }

  public searchChange(value: string): void {
    this.value = value;
    this.provider.search(value);
  }

  public navigateToCustomer(customer: { id: string }): void {
    this.router.navigate([RoutesConfig.CUSTOMERS, customer.id]);
    this.handleClose();
  }

  public navigateToCustomers(): void {
    this.router.navigate([RoutesConfig.CUSTOMERS], {
      queryParams: {
        [`customers[filter][${SEARCH_KEY}]`]: this.value,
      },
    });
    this.handleClose();
  }

  public navigateToPatient(patient: {
    id: string;
    patrons: { id: string }[];
  }): void {
    this.router.navigate([RoutesConfig.PATIENTS, patient.id]);
    this.handleClose();
  }

  public navigateToPatients(): void {
    this.router.navigate([RoutesConfig.PATIENTS], {
      queryParams: {
        [`patients-list[filter][${SEARCH_KEY}]`]: this.value,
      },
    });
    this.handleClose();
  }

  public navigateToRecord(record: { id: string }): void {
    this.router.navigate([RoutesConfig.RECORDS, record.id]);
    this.handleClose();
  }

  public navigateToRecords(): void {
    this.router.navigate([RoutesConfig.RECORDS], {
      queryParams: {
        [`'records-list'[filter][${SEARCH_KEY}]`]: this.value,
      },
    });
    this.handleClose();
  }

  private _clearSearch(): void {
    this.value = '';
    this.provider.search(this.value);
  }
}
