import { CrmEndpointModuleConfig } from 'common-module/endpoint';

import { environment } from '~/environments/environment';

export const endpointConfig: CrmEndpointModuleConfig = {
  endpoints: [
    {
      name: 'crm',
      baseUrl: environment.crmUrl,
      withCredentials: true,
      httpHeaders: { Authorization: environment.crmAuth },
    },
    {
      name: 'assets',
      baseUrl: 'assets',
    },
  ],
};
