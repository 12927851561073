export const requestDuration = (timeEntry?: PerformanceResourceTiming) => {
  if (!timeEntry) {
    return 0;
  }
  const { responseStart, requestStart } = timeEntry;
  if (!responseStart || !requestStart) {
    return 0;
  }
  return timeEntry.responseStart - timeEntry.requestStart;
};
