export type TrackingConfig = {
  ignoredUrls: string[];
  /**
   * Threshold in B to log http requests above defined value.
   * Default: 1000000B
   */
  sizeThreshold: number;
  /**
   * Threshold in ms to log http request that exceeds defined time
   * Default: 500ms
   */
  timeThreshold: number;
  ignoreUrlRegex: string;
};

export const defaultTrackingConfig: TrackingConfig = {
  ignoredUrls: ['/api/health', '/env.json'],
  /**
   * Threshold in B to log http requests above defined value.
   * Default: 1000000B
   */
  sizeThreshold: 1000000,
  /**
   * Threshold in ms to log http request that exceeds defined time
   * Default: 500ms
   */
  timeThreshold: 1000,
  ignoreUrlRegex: 'w*[a-z]([.-][A-Za-z0-9]*)*(.)?js',
};
