import { HttpErrorResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { CrmInterceptHandlerService } from 'common-module/auth';
import { CrmModalService } from 'common-module/modal';
import { throwError } from 'rxjs';

import { UserApiService } from '~/api/user/user-api.service';
import { ConnectionService } from '~/shared/services/connection.service';

@Injectable()
export class InterceptHandlerProvider extends CrmInterceptHandlerService {
  protected connectionService = inject(ConnectionService);
  protected modalService = inject(CrmModalService);
  protected override userService = inject(UserApiService);

  override handleError(error: HttpErrorResponse) {
    if (this.interceptHttpCode(error)) {
      this.modalService.closeAll();
      this.userService.tryReLogin().subscribe();
    }

    if ([0].includes(error.status)) {
      this.connectionService.checkConnection().subscribe();
    }

    return throwError(() => error);
  }
}
