import { Injectable } from '@angular/core';
import {
  CrmLayoutCollapseProvider,
  CrmLayoutSiderControl,
} from 'common-module/layout-v2';
import { Observable, of } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class LayoutCollapseProvider extends CrmLayoutCollapseProvider {
  override getSiderControl(): Observable<CrmLayoutSiderControl> {
    return of(this.layoutProvider.siderLeftControl);
  }
}
