import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { CrmAppComponent } from 'common-module/core';
import { NzSpinModule } from 'ng-zorro-antd/spin';

import { AppService } from './app.service';

@Component({
  standalone: true,
  selector: 'app-root',
  templateUrl: './app.component.html',
  styles: [
    `
      .loading-overlay {
        display: flex;
        align-items: center;
        justify-content: center;
        position: fixed;
        background-color: #fff;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 10000;

        &--light {
          background-color: rgba(255, 255, 255, 50%);
        }
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CrmAppComponent, NzSpinModule],
})
export class AppComponent {
  protected service = inject(AppService);

  protected loading = toSignal(this.service.loading$);
}
