import {
  ChangeDetectionStrategy,
  Component,
  computed,
  HostBinding,
  inject,
  OnInit,
  runInInjectionContext,
  Signal,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { CrmApiService } from 'common-module/api';
import { CrmLayoutV2FragmentDirective } from 'common-module/layout-v2';
import { NzTooltipDirective } from 'ng-zorro-antd/tooltip';
import { map, Observable } from 'rxjs';

import { environment } from '~/environments/environment';

import { LayoutVersionProvider } from './layout-version.provider';

type Data = {
  current: string;
  versions: { module: string; version: string }[];
};

@Component({
  standalone: true,
  selector: 'app-layout-version',
  template: `
    @if (!compact()) {
      <div class="layout-version__name" nz-tooltip [nzTooltipTitle]="versions">
        {{ name }}
      </div>
    }

    <span class="layout-version__version">
      <span>{{ data().current }}</span>
      @if (env) {
        <span class="layout-version__version__env">-{{ env }}</span>
      }
    </span>

    @if (!compact()) {
      <span>by</span>
      <img
        class="layout-version__image"
        alt="logo"
        src="/assets/images/logo-vets-default.svg"
      />
    }

    <ng-template #versions>
      @if (data().versions.length > 0) {
        <div class="layout-version__module-tooltip">
          @for (mod of data().versions; track mod.module) {
            <span class="layout-version__module-version">
              <span>{{ mod.module }}</span>
              <span>{{ mod.version }}</span>
            </span>
          }
        </div>
      }
    </ng-template>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NzTooltipDirective],
  providers: [LayoutVersionProvider],
})
export class LayoutVersionComponent
  extends CrmLayoutV2FragmentDirective<LayoutVersionProvider>
  implements OnInit
{
  @HostBinding('class')
  protected readonly hostClass = 'layout-version';

  protected name!: string;
  protected env?: string;
  protected compact!: Signal<boolean>;
  protected data!: Signal<Data>;

  ngOnInit() {
    this.initProvider(LayoutVersionProvider);

    this.name = environment.projectName ?? 'Docut';
    this.env = environment.production ? undefined : environment.env;

    runInInjectionContext(this.injector, () => {
      this.compact = computed(() => this.siderControl?.collapsed() ?? false);

      const data$: Observable<Data> = inject(CrmApiService)
        .apiVersion()
        .pipe(
          map(({ release, modules }) => ({
            current: release ?? environment.version ?? '0.0.0',
            versions: modules ?? [],
          })),
        );

      this.data = toSignal(data$, {
        initialValue: { current: environment.version ?? '0.0.0', versions: [] },
      });
    });
  }
}
