import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NzSizeLDSType } from 'ng-zorro-antd/core/types';
import { NzSpinModule } from 'ng-zorro-antd/spin';

@Component({
  standalone: true,
  selector: 'app-content-loading',
  templateUrl: './content-loading.component.html',
  imports: [NgIf, NzSpinModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentLoadingComponent {
  @Input() public loading = false;
  @Input() public size: NzSizeLDSType = 'large';
  @Input() public tip = 'Loading...';
  @Input() public parentClass = 'mt-3';
}
