import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { CrmConfigService } from 'common-module/core';
import { CrmSafeAny } from 'common-module/core/types';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { requestDuration } from '../helper/error-event.helper';
import { EventSeverity, HttpEventSubject } from '../model/error.event';
import {
  defaultTrackingConfig,
  TrackingConfig,
} from '../model/tracking.config';
import { ErrorTrackingService } from '../services/error-tracking.service';

@Injectable()
export class TrackingInterceptor implements HttpInterceptor {
  private _defaultIgnoredUrls = ['/journal/frontend'];

  private _warned = false;
  private config!: TrackingConfig;

  private tracking = inject(ErrorTrackingService);
  private configService = inject(CrmConfigService);

  constructor() {
    this.config = this.configService.mergeConfig(
      defaultTrackingConfig,
      'modules.tracking'
    );
  }

  public intercept(
    req: HttpRequest<CrmSafeAny>,
    next: HttpHandler
  ): Observable<HttpEvent<CrmSafeAny>> {
    return next.handle(req).pipe(
      tap((res: HttpEvent<CrmSafeAny>) => {
        if (res instanceof HttpResponse) {
          setTimeout(() => {
            this.performanceTracking(req, res);
          }, 1000);
        }
      }),
      catchError((err) => {
        const { ignoredUrls } = this.config;
        if (
          [...this._defaultIgnoredUrls, ...ignoredUrls].some((url) =>
            req.url.includes(url)
          )
        ) {
          return throwError(() => err);
        }
        if (err.status === 0) {
          // NOTE: disconnected error
          this.tracking.trackHttpError(err, EventSeverity.critical);
        } else {
          this.tracking.trackHttpError(err, EventSeverity.error);
        }

        return throwError(() => err);
      })
    );
  }

  private performanceTracking(
    req: HttpRequest<CrmSafeAny>,
    res: HttpResponse<CrmSafeAny>
  ) {
    const { ignoreUrlRegex, timeThreshold, sizeThreshold } = this.config;
    const { urlWithParams: url } = req;

    if (ignoreUrlRegex && url.match(new RegExp(ignoreUrlRegex))) {
      return;
    }

    const perfTimes = (
      performance.getEntriesByType('resource') as PerformanceResourceTiming[]
    ).filter((pe) => pe?.initiatorType === 'xmlhttprequest');
    const timePerformance = perfTimes.find(
      (pt) => pt.name === req.urlWithParams
    );

    let subject;
    if (timePerformance) {
      if (
        (!timePerformance.responseStart || !timePerformance.requestStart) &&
        !this._warned
      ) {
        console.warn(
          'Request time not set. `Timing-Allow-Origin` header possibly missing from response'
        );
        this._warned = true;
      }
      const reqDuration = requestDuration(timePerformance);
      if (reqDuration >= timeThreshold) {
        subject = HttpEventSubject.slowRequest;
      }
    }

    const size = +(res?.headers?.get('content-length') ?? 0);
    if (size >= sizeThreshold) {
      subject = HttpEventSubject.bigRequest;
    }

    if (!subject) {
      return;
    }

    this.tracking.trackHttpPerformance(
      {
        size,
        time: requestDuration(timePerformance),
        url,
      },
      subject,
      EventSeverity.warning
    );
  }
}
