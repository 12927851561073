import { WritableStream } from 'streamsaver';

import { StringStreamEncoder } from '../encoders/string-stream.encoder';

import { WriteStream } from './write.stream';

export type StringStreamInput = string | string[];

export class StringStream extends WriteStream<StringStreamInput, Uint8Array> {
  constructor(
    protected override readonly _stream: WritableStream,
    protected override readonly _encoder: StringStreamEncoder,
    protected _separator: string = ','
  ) {
    super(_stream, _encoder);
  }

  public writeln(chunk: StringStreamInput): Promise<void> {
    return this.write(`${this._getValue(chunk)}\n`);
  }

  private _getValue(chunk: StringStreamInput): string {
    if (typeof chunk === 'string') {
      return chunk;
    } else {
      return chunk.join(this._separator);
    }
  }
}
