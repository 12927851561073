<div class="login-title">
  {{ 'auth.login.title' | crmTranslate | uppercase }}
</div>
<p class="login-text">{{ 'auth.login.message' | crmTranslate }}</p>

@if (message(); as msg) {
  <nz-alert
    class="login-alert"
    [nzType]="msg.type"
    [nzDescription]="'auth.login.messages.' + msg.message | crmTranslate"
  ></nz-alert>
}

<crm-form [provider]="formProvider"></crm-form>
<div class="login-forgotten-password" (click)="handleForgottenPassword()">
  <i nz-icon nzType="question-circle" nzTheme="outline" class="mr-1"></i>
  <span>{{ 'auth.actions.forgotten' | crmTranslate }}</span>
</div>
<button
  nz-button
  nzType="primary"
  nzBlock
  nzSize="large"
  [nzLoading]="isLoading()"
  (click)="handleSubmit()"
>
  {{ 'auth.login.title' | crmTranslate | uppercase }}
</button>
<p class="login-no-account">
  {{ 'auth.login.contactAdmin' | crmTranslate }}
</p>
