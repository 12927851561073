import { Injectable } from '@angular/core';
import { CreateWriteStreamOptions } from 'streamsaver';

import { StringStreamEncoder } from '../encoders/string-stream.encoder';
import { StringStream } from '../streams/string.stream';

import { WriteStreamService } from './write-stream.service';

@Injectable()
export class StringStreamService extends WriteStreamService {
  public createStream(
    filename: string,
    options?: CreateWriteStreamOptions
  ): StringStream {
    return new StringStream(
      this.createWritableStream(filename, options),
      new StringStreamEncoder()
    );
  }
}
