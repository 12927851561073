<nz-input-group
  class="input-search"
  nzSize="large"
  [nzSuffix]="suffixIconSearch"
>
  <input
    #inputSearch
    type="text"
    autocomplete="off"
    [id]="id && id + '-search'"
    nz-input
    [formControl]="control"
    [placeholder]="placeholder | crmTranslate"
  />
</nz-input-group>
<ng-template #suffixIconSearch>
  <i *ngIf="!value" nz-icon nzType="search"></i>
  <i
    *ngIf="value"
    class="clickable"
    nz-icon
    nzType="close-circle"
    nzTheme="fill"
    (click)="handleClear()"
  ></i>
</ng-template>
