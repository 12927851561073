import { ChangeDetectionStrategy, Component } from '@angular/core';
import { NzIconModule } from 'ng-zorro-antd/icon';

import { environment } from '~/environments/environment';

@Component({
  standalone: true,
  selector: 'app-layout-chat',
  template: `
    @if (href) {
      <a [href]="href" class="chat-link" target="_blank">
        <i nz-icon nzType="comment" nzTheme="outline"></i>
      </a>
    }
  `,
  styles: [
    `
      a {
        font-size: 24px;
        color: #7b7b7b;
        margin-right: 16px;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NzIconModule],
})
export class LayoutChatComponent {
  protected href = environment.mattermostUrl;
}
