import en from '@angular/common/locales/en';
import sk from '@angular/common/locales/sk';
import cs from '@angular/common/locales/cs';
import { CrmTranslateConfig } from 'common-module/translate';
import {
  enUS as dateFnsEn,
  sk as dateFnsSk,
  cs as dateFnsCs,
} from 'date-fns/locale';
import { en_US, sk_SK, cs_CZ } from 'ng-zorro-antd/i18n';

export const translateConfig: CrmTranslateConfig = {
  locale: {
    defaultLocale: 'sk',
    locales: {
      sk: {
        ngLocale: sk,
        nzLocale: sk_SK,
        dateFnsLocale: dateFnsSk,
      },
      cs: {
        ngLocale: cs,
        nzLocale: cs_CZ,
        dateFnsLocale: dateFnsCs,
      },
      en: {
        ngLocale: en,
        nzLocale: en_US,
        dateFnsLocale: dateFnsEn,
      },
    },
  },
};
