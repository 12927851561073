import { inject, Injectable } from '@angular/core';
import {
  CrmLayoutMenuProvider,
  CrmLayoutSiderControl,
} from 'common-module/layout-v2';
import { map, Observable } from 'rxjs';

import { UserPermissionsService } from '~/api/user/user-permissions.service';
import { MenuItem } from '~/config/menu.config';

@Injectable({ providedIn: 'root' })
export class LayoutMenuProvider extends CrmLayoutMenuProvider {
  protected readonly permissionsService = inject(UserPermissionsService);

  override items$(_options: {
    siderControl?: CrmLayoutSiderControl;
  }): Observable<MenuItem[]> {
    return this.permissionsService
      .getPermissions()
      .pipe(map(() => this.permissionsService.getMenuItems()));
  }
}
