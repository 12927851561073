import { inject, Injectable } from '@angular/core';
import {
  CrmLayoutLogoConfig,
  CrmLayoutLogoProvider,
} from 'common-module/layout-v2';
import { map, Observable } from 'rxjs';

import { ClinicsApiService } from '~/api/clinics/clinics-api.service';

@Injectable({ providedIn: 'root' })
export class LayoutLogoProvider extends CrmLayoutLogoProvider {
  protected readonly clinicsService = inject(ClinicsApiService);

  override getComponentConfig(): Observable<CrmLayoutLogoConfig> {
    return this.clinicsService.getLogoUrl().pipe(
      map((logo) => ({
        defaultLogoPath: logo,
        smallLogoPath: logo,
      }))
    );
  }
}
