import { inject, Injectable } from '@angular/core';
import { CrmChangelogModalTrigger } from 'common-module/changelog';
import { race } from 'rxjs';

import { UserApiService } from '~/api/user/user-api.service';

@Injectable()
export class ChangelogModalTrigger extends CrmChangelogModalTrigger {
  private userApiService = inject(UserApiService);

  constructor() {
    super();

    race(this.userApiService.profile$, this.userApiService.login$).subscribe(
      this.trigger$,
    );
  }
}
